.the-curve {
  background-color: #303030;
  position: relative;
  height: 700px;
  padding-bottom: 3%;
  padding-right: 1%;
  padding-left: 1%;
}

.country-search {
  position: absolute;
  top: 10px;
  left: 10px;
}

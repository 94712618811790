.root {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.control-container {
  display: flex;
  flex-grow: 1;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-left: 10%;
  margin-right: 10%;
  justify-content: center;
  align-items: center;
}

.play-stop-buttonplay-stop-button {
  flex-grow: 1;
}

.MuiSlider {
  flex-grow: 3;
  margin-top: 1%;
}

.map-container {
  flex-grow: 1;
  width: 100%;
  height: 700px;
}

.date-picker {
  flex-grow: 1;
  margin-bottom: 2%;
}

.top-navbar {
  text-align: left;
  flex-grow: 1;
}

.world-info-mobile {
  background: #272626;
  right: 10px;
  bottom: 30px;
  padding: 10px;
}

@media only screen and (max-width: 600px) {
  .map-container {
    height: 500px;
  }
}

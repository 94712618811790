#map {
  position: relative;
  height: inherit;
  width: inherit;
}

#tooltip {
  position: absolute;
  background: #272626;
  opacity: 90%;
  border-radius: 12px;
  margin: 10px;
  padding: 10px;
  width: 200px;
}

.search-bar {
  position: absolute;
  top: 10px;
  left: 10px;
}

.world-info {
  position: absolute;
  z-index: 1000;
  background: #272626;
  border-radius: 12px;
  right: 10px;
  bottom: 30px;
  padding: 10px;
  width: 200px;
  opacity: 0.9;
}

.MuiTextField-root {
  background-color: whitesmoke;
}

.active {
  color: #ffb300;
}

.confirmed {
  color: #c62828;
}

.recovered {
  color: #00897b;
}

.deaths {
  color: #bdbdbd;
}

.header {
  color: #ffffff;
  line-height: 0px;
}
